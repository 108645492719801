
import React from "react";
import Button from "react-bootstrap/Button";
import "./CustomButton.css"; // For additional styles

interface CustomButtonProps extends React.HTMLProps<HTMLButtonElement> {
    label: string ;
    padding?: string;
    width?: string;
    height?: string;
    margin?: string;
    fontSize?: string;
    type?: "button" | "submit" | "reset";
    onClick?: () => void;
    disabled?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = ({
    label,
    padding,
    width,
    height,
    margin,
    fontSize,
    onClick,
    type = "button",
    disabled = false,
}) => {
    return (
        

        <Button
            
            onClick={onClick}
            style={{
                padding: padding,
                width: width,
                height: height,
                margin:margin,
                backgroundColor: "var(--primary-color)",
                border: "none",
                borderRadius: "8px",
                fontWeight: "bold",
                fontSize: fontSize,
            }}
            
            type={type}
            disabled={disabled}
        >
            {label}
        </Button>
        
    );
};

export default CustomButton;

