import React from "react";
import './Stats.css';

const Stats:React.FC= () => {
    return (
        <div className="stats-container">
            <h1>Stats</h1>
            <div className="stats-content">
                <div className="stats-content__item">
                    <h3>Number of Resumes</h3>
                    <p>10</p>
                </div>
                <div className="stats-content__item">
                    <h3>Number of Users</h3>
                    <p>10</p>
                </div>
            </div>
        </div>
    );
}

export default Stats;



