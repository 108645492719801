import React, {useState} from "react";
import "./Upload.css";



import Header from "../Header/Header";

import CustomButton from "../../utils/Custom/Button/CustomButton";
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import { useAppDispatch } from "../../store/hooks";
import { useAppSelector } from "../../store/hooks";
import { useNavigate } from "react-router-dom";
import api from "../../managers/api/api";

import {saveNewUserResume} from "../../store/UserSlice";

interface UploadProps {
    
}

const Upload: React.FC<UploadProps> = () => {

    const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated)
    // const resumeRef=useRef<{getResume:()=>UserResume}>(null);
    // const userResumes=useAppSelector((state)=>state.user.userResumes);

    // const [presentResume,setPresentResume]=useState<string>("Select Resume");
    // const [resume,setResume]=useState<UserResume>();
    // const [resumeLoading,setResumeLoading]=useState<boolean>(true);
    // const [showResume,setShowResume]=useState<boolean>(false);
    // const [isSaved,setIsSaved] = useState<boolean | null>(null);

    const dispatch = useAppDispatch();
    const navigate=useNavigate();
    const [parsing,setParsing] = useState<boolean>(false);
    const [uploadStatus,setUploadStatus] = useState<boolean|null>(null);

    const handleUpload = async (e: any) => {
        e.preventDefault();
        setParsing(true);
        // setIsSaved(null);
        const file = e.target[0].files[0];
        console.log("File to Upload : ",file);

        const response=await api.uploadResume(file);

        if(response.status===200){
            console.log("Resume Uploaded Successfully");
            console.log(response.data);
            const resume=response.data.resume
            const fileId=response.data.file_id;
            const nickname=response.data.nickname;
            const filename=response.data.filename;
            dispatch(saveNewUserResume({
                key:fileId,
                resume:resume,
                nickname:nickname,
                filename:filename
            }));
            setParsing(false);
            setUploadStatus(true);
            
            // const uploadResponse=await api.uploadToS3(file,response.data.uploadURL);
            // if(uploadResponse.status===200){
            //     console.log("Resume Uploaded to S3 Successfully");
            // }else{
            //     console.log("Error in Uploading Resume to S3");
            // }


        }else{
            console.log("Error in Uploading Resume");
            setParsing(false);
            setUploadStatus(false);
        }

    }

    // const handleSave = async () => {
    //     if(resumeRef.current){
    //         let resume:UserResume=resumeRef.current.getResume();
    //         console.log("Resume : ",resume);

    //         const skills:Skill[]=[]
    //         resume["skills"].map((skill)=>{
    //             if(skill["category"]!==""){
    //                 skills.push(skill);
    //             }
    //             return skill;
    //         })

    //         // console.log("Skills : ",skills);
    //         resume={...resume,skills:skills};
            
    //         dispatch(saveUserResume({
    //             key:"",
    //             resume:resume
    //         }));

    //         const response=await api.saveResume(resume);
    //         if(response.status===200){
    //             setIsSaved(true);
    //             // console.log("Resume Saved Successfully");
    //         }else{
    //             setIsSaved(false);
    //             // console.log("Error in Saving Resume");
    //         }
    //     }
    // }

    // useEffect(()=>{

    //     const fetchResume=async(key:string)=>{
    //         const response=await api.getResumeJSON(key);
    //         console.log("Resume Response : ",response);
    //         if(response.status===200){
    //             const resume=response.data.resume;
    //             console.log("Resume : ",resume);
    //             setResume(resume);
    //             setPresentResume(userResumes[key].nickname);
    //             dispatch(saveUserResume({
    //                 key:key,
    //                 resume:resume
    //             }))
    //         }else{
    //             console.log("Error in Fetching Resume");
    //         }
    //     }

    //     const numberOfResumes=Object.keys(userResumes).length;
    //     console.log("Number of Resumes : ",numberOfResumes);
    //     if(numberOfResumes===0){
    //         setShowResume(false);
    //         return;
    //     }else{
    //         setShowResume(true);
    //     }
    //     console.log("User Resumes : ",userResumes);
    //     const firstkey=Object.keys(userResumes)[0];
    //     const firstResume=userResumes[firstkey]
    //     if(!firstResume.resume){
    //         fetchResume(firstkey);
    //     }else{
    //         setPresentResume(userResumes[Object.keys(userResumes)[0]].nickname)
    //         setResume(userResumes[Object.keys(userResumes)[0]].resume);
    //     }
    // },[userResumes])

   

    return (
        <>
            <div className="upload-container">
                <Header isAuthenticated={isAuthenticated} />
                <div className="upload-container-body">
                    
                    <div className="upload-form-container">
                        {
                            parsing ? (
                                <div className="upload-container-parsing">
                                    <Spinner id="parsing-load" animation="grow" /> <span id="parsing-text">Parsing Resume ...</span>
                                </div>
                            ) : (
                                <>
                                    <Form className="upload-container-upload-form" onSubmit={handleUpload}>
                                        <Form.Group   controlId="formFile" >
                                            <Form.Control type="file" />
                                        </Form.Group>
                                        
                                        {/* <Button id="upload-container-input-submit"  variant="primary" type="submit">
                                            Upload
                                        </Button> */}
                                        <CustomButton margin="0px 0px 0px 30px" type="submit" label="Upload" />
                                    </Form>

                                    {
                                        uploadStatus!==null ? (
                                            uploadStatus ? (
                                                <div className="upload-feedback-container upload-success">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="var(--green-color)" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M7.75 11.9999L10.58 14.8299L16.25 9.16992" stroke="var(--green-color)" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg><span>Resume Uploaded Successfully</span>
                                                </div>
                                            ) : (
                                                <div className="upload-feedback-container upload-failure">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="var(--red-color)" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M9.16992 14.8299L14.8299 9.16992" stroke="var(--red-color)" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M14.8299 14.8299L9.16992 9.16992" stroke="var(--red-color)" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg><span>Error in Uploading Resume</span> 
                                                </div>
                                            )
                                        ) : (
                                            <></>
                                        )
                                    }
                                
                                </>
                                
                            )
                        }
                    </div>
                    <div className="upload-container-resume">
                        {
                            uploadStatus && <CustomButton margin="10px 0px 30px 0px" label="View Resume" onClick={()=>navigate("/resumes")} />

                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Upload;
