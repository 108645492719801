import React,{useState} from "react";
import "./Home.css";


import Header from "../Header/Header";
import { Container,Col,Row} from "react-bootstrap";
import CustomButton from "../../utils/Custom/Button/CustomButton";
import CustomModal from "../../utils/Custom/Modal/CustomModal";
import { Form } from "react-bootstrap";
import { useAppSelector } from "../../store/hooks";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import ViewIcon from "../../icons/ViewIcon";
import EditIcon from "../../icons/EditIcon";
import TrashIcon from "../../icons/TrashIcon";

import { setResumeNickname ,removeUserResume,setDefaultResume} from "../../store/UserSlice";
import api from "../../managers/api/api";
import CustomSticker from "../../utils/Custom/Sticker/CustomSticker";

const Home: React.FC = () => {

    const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);
    const user=useAppSelector((state)=>state.user);
    const dispatch = useAppDispatch();

    const screenWidth=window.innerWidth;
    // console.log("Screen Width : ",screenWidth);

    const [inputs, setInputs] = useState({ nickname: '' });
    const [modal, setModal] = useState({
        nickNameChangeShow: false,
        key: "",
    });

    const handleNicknameChange = async () => {
        // console.log("Nickname : ",inputs.nickname);
        const response=await api.changeNickname(modal.key,inputs.nickname);
        // console.log("nickname Response : ",response);
        if(response.status===200){
            dispatch(setResumeNickname({key:modal.key,nickname:inputs.nickname}));
        }
        setModal({...modal,nickNameChangeShow:false,key:""});
    }

    const handleRemoveResume = async (key:string) => {
        console.log("Removing Resume");
        const response=await api.removeResume(key);
        console.log("Remove Response : ",response);

        if(response.status===200){
            dispatch(removeUserResume(key));
        }

    }

    const handleViewResume = async (key:string) => {
        // console.log("Viewing Resume");
        const response=await api.getResume(key);

        // console.log("Resume URL: ",response);
        const url=response.data.url;

        // console.log("URL : ",url);
        const responseQ = await fetch(url);
        if (!responseQ.ok) {
            throw new Error("Failed to fetch the file.");
        }

        // Convert the response to a Blob
        const blob = await responseQ.blob();
        // Create a Blob URL
        const blobUrl = URL.createObjectURL(blob);
        // window.open(blobUrl, "_blank");

        if(screenWidth<768){
            const confirmed=window.confirm("Open the resume in a new tab?");
            if(confirmed){
                window.open(blobUrl, "_blank");
            }
            return
        }

        // console.log("Blob URL : ",blobUrl);

        // Open the Blob URL in a new tab
        window.open(blobUrl, "_blank");

    }

    const handleSetDefault = async (key:string) => {
        // console.log("Setting Default Resume");
        const response=await api.setDefaultResume(key);
        // console.log("Set Default Response : ",response);

        if(response.status===200){
            dispatch(setDefaultResume(key));
        }
    }

    // console.log("showing key for modal : ",modal.key);

    const navigate=useNavigate();

    // console.log("User : ",user);
   

    return (
        <div className="home-container">
            <Header isAuthenticated={isAuthenticated} />
            <Container>
                <Row className="m-1">
                    <Col xs={12} md={6} lg={4} xl={4}>
                        {/* <Row className="mb-3">
                            <Col>
                                <div className="mini-container">
                                    <div className="home-creds-container">

                                        <div className="home-creds-footer">
                                            <CustomButton label="Manage Credentials" onClick={()=>navigate('/creds')}/>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col>
                                <div className="mini-container m-1">
                                    <div className="home-create-resume-container">
                                        <div className="home-create-resume-body p-3 fw-bold">
                                            <span>Starting from scratch?</span>
                                        </div>

                                        <div className="home-create-resume-footer">
                                            <CustomButton label="Create Your Resume" onClick={()=>navigate("/createResume")}/>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row >
                            <Col>
                                <div className="mini-container m-1">
                                    <div className="home-stats-container">
                                        <div className="home-stats-body p-3 fw-bold">
                                            <span>You have {parseInt((user.tokensLeft/5000).toString())} resumes left.</span>
                                        </div>

                                        <div className="home-stats-footer">
                                            <CustomButton label="View Stats" />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={4} >
                        <div className="big-container m-1">
                            <div className="home-q-container">
                                <CustomSticker label="beta" color="var(--green-color)" />
                                <div className="home-q-body p-3 fw-bold">
                                <span>Find jobs that match your skills.</span><br />
                                <span>Type your query to discover relevant job opportunities.</span><br /><br /><br></br>
                                <span>Example Queries:</span><br />
                                <span>1. Software Engineer jobs in San Francisco.</span><br />
                                <span>2. Skilled in React, Find jobs with sponsorship and less than 5 years of experience.</span>

                                </div>
                                <div className="home-q-footer">
                                    <CustomButton label="Find Jobs" onClick={()=>navigate("/jobs")}/>
                                    
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={4} >
                        <div className="big-container m-1">
                            <div className="home-resumes-container">
                                <div className="home-resumes-body">
                                    <div className="home-resumes-list">
                                        {
                                            Object.keys(user.userResumes).length===0?
                                            <div className="p-3 fw-bold">
                                                <span className="home-container-item">No Resumes Uploaded</span>
                                            </div>:
                                            Object.keys(user.userResumes).map((key:string)=>{
                                                return <div key={key} className="home-container-user-resume">
                                                    <div className="home-container-resume-div1">
                                                        <span className="home-container-item">
                                                            {user.userResumes[key].file_name}
                                                            {
                                                                user.defaultResume===key ? <CustomSticker label="default" color="var(--primary-color)" fontSize="0.6rem" padding="2px 6px"/> : <></>
                                                            }
                                                        </span>
                                                        <span className="home-container-item" onClick={()=>handleViewResume(key)}><ViewIcon /></span>
                                                    </div>
                                                    <span className="home-container-resume-div1">
                                                        <span className="home-container-item">
                                                            {user.userResumes[key].nickname}
                                                            <span className="home-container-nickname-edit" onClick={()=>setModal({...modal,nickNameChangeShow:true,key:key})}>
                                                                <EditIcon width="18px" height="18px"/>
                                                            </span>
                                                        </span>
                                                        {
                                                            user.defaultResume===key ? <></> : <span className="home-container-set-default-btn" onClick={()=>handleSetDefault(key)}>
                                                            <CustomSticker label="set default" color="var(--primary-color)" />
                                                        </span>
                                                            
                                                        }
                                                        
                                                        <span className="home-container-item-remove" onClick={()=>handleRemoveResume(key)}>
                                                            <TrashIcon color="red" />
                                                        </span>
                                                        
                                                    </span>
                                                    
                                                    {/* <div className="home-container-item-remove" onClick={()=>handleRemoveResume(key)}><TrashIcon color="white" /></div> */}
                                                </div>
                                            })
                                        }
                                        <CustomModal 
                                            show={modal.nickNameChangeShow} 
                                            handleClose={()=>setModal({...modal,nickNameChangeShow: false})} 
                                            handleSave={handleNicknameChange}
                                            title="Change Nickname" 
                                            body={
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    placeholder="Nickname"
                                                    onChange={(e) => setInputs({...inputs, nickname: e.target.value})}
                                                />
                                                
                                            }
                                        />

                                    </div>
                                </div>
                                <div className="home-resumes-footer">
                                    <CustomButton margin="2px" fontSize="13px" padding="4px 8px" label="Edit Resume" onClick={()=>navigate("/resumes")} />
                                    <CustomButton margin="2px" fontSize="13px" padding="4px 8px" label="Upload new resume" onClick={()=>navigate("/upload")} />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="m-1">
                    <Col xs={12} md={6} lg={6} xl={6} >
                        <div className="mini-container m-1">
                            <div className="home-tailor-container">
                                <div className="home-tailor-body p-3 fw-bold">
                                    <span>Tailor your resume using cutting edge AI models.</span><br></br>
                                    {/* <span>Check out the chrome extension at <a href="https://chromewebstore.google.com/detail/xpress-cv/pgljbepfgpmpkihennjhgmaacgeepoid" rel="noopener noreferrer" target="_blank">here</a> </span> */}
                                </div>
                                <div className="home-tailor-footer">
                                    <CustomButton label="Tailor Resume" onClick={()=>navigate("/generate")}/>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6}>
                        <div className="mini-container m-1">
                            <div className="home-support-container">
                                <div className="home-support-body p-3 fw-bold">
                                    <span>Need help? Reach out to us.</span>
                                </div>
                                <div className="home-support-footer">
                                    <CustomButton label="Contact Us" onClick={()=>navigate('/contact')}/>
                                </div>
                            </div>
                        </div>
                    </Col>

                </Row>
            </Container>
            
        </div>
    );
}

export default Home;

