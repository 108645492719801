import React,{useState,useEffect,useRef} from "react";
import './YourResumes.css';

import Resume from "../Resume/Resume";
import Header from "../Header/Header";
import CustomDropDown from "../../utils/Custom/Dropdown/CustomDropDown";
import CustomButton from "../../utils/Custom/Button/CustomButton";
import CustomSpinner from "../../utils/Custom/Spinner/CustomSpinner";

import { useAppSelector,useAppDispatch } from "../../store/hooks";
import { useNavigate } from "react-router-dom";
import { saveUserResume } from "../../store/UserSlice";
import { UserResume,Skill } from "../../utils/_interfaces/Resume";
import api from "../../managers/api/api";

const YourResumes:React.FC = () => {
    const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated)
    const dispatch=useAppDispatch();
    const navigate=useNavigate();
    const userResumes=useAppSelector((state)=>state.user.userResumes);
    
    const [presentResume,setPresentResume]=useState<string>("Select Resume");
    const [resume,setResume]=useState<UserResume>();
    const resumeKeyRef=useRef<string>("");
    const [compMessage,setCompMessage]=useState<string>("");
    // const [resumeLoading,setResumeLoading]=useState<boolean>(true);
    const [showResume,setShowResume]=useState<boolean>(false);
    const [isSaved,setIsSaved]=useState<boolean | null>(null);
    const resumeRef=useRef<{getResume:()=>UserResume}>(null);
    
    const handleSave = async () => {
        console.log("Saving Resume");
        if(resumeRef.current){
            let resume:UserResume=resumeRef.current.getResume();
            console.log("Resume : ",resume);

            const skills:Skill[]=[]
            resume["skills"].map((skill)=>{
                if(skill["category"]!==""){
                    skills.push(skill);
                }
                return skill;
            })

            // console.log("Skills : ",skills);
            resume={...resume,skills:skills};
            console.log(resumeKeyRef.current);
            const response=await api.saveResume(resume,resumeKeyRef.current);
            if(response.status===200){
                setIsSaved(true);
                dispatch(saveUserResume({
                    key:resumeKeyRef.current,
                    resume:resume
                }))
                // console.log("Resume Saved Successfully");
            }else{
                setIsSaved(false);
                // console.log("Error in Saving Resume");
            }
        }
    }
    useEffect(()=>{

        const fetchResume=async(key:string)=>{
            const response=await api.getResumeJSON(key);
            console.log("Resume Response : ",response);
            if(response.status===200){
                const resume=response.data.resume;
                console.log("Resume : ",resume);
                setResume(resume);
                setPresentResume(userResumes[key].nickname);
                dispatch(saveUserResume({
                    key:key,
                    resume:resume
                }))
            }else{
                console.log("Error in Fetching Resume");
            }
        }

        const numberOfResumes=Object.keys(userResumes).length;
        console.log("Number of Resumes : ",numberOfResumes);
        if(numberOfResumes===0){
            setShowResume(false);
            setCompMessage("No Resumes Found");
            // setResumeLoading(false);
            return;
        }else{
            setShowResume(true);
        }
        console.log("User Resumes : ",userResumes);
        const firstkey=Object.keys(userResumes)[0];
        const firstResume=userResumes[firstkey]
        if(!firstResume.resume){
            fetchResume(firstkey);
        }else{
            setPresentResume(userResumes[Object.keys(userResumes)[0]].nickname)
            setResume(userResumes[Object.keys(userResumes)[0]].resume);
        }
        // eslint-disable-next-line
    },[])

    useEffect(()=>{
        const fetchResume=async(key:string)=>{
            const response=await api.getResumeJSON(key);
            console.log("Resume Response : ",response);
            if(response.status===200){
                const resume=response.data.resume;
                console.log("Resume : ",resume);
                setResume(resume);
                setPresentResume(userResumes[key].nickname);
                dispatch(saveUserResume({
                    key:key,
                    resume:resume
                }))
            }else{
                console.log("Error in Fetching Resume");
            }
        }

        console.log("Present Resume : ",presentResume);
        for(let key in userResumes){
            if(userResumes[key].nickname===presentResume){
                resumeKeyRef.current=key;
                if(!userResumes[key].resume){
                    console.log("Fetching Resume");
                    fetchResume(key);
                }else{
                    console.log("Setting Resume");
                    setResume(userResumes[key].resume);
                }
            }
        }
    },[presentResume,dispatch,userResumes])

    return (
        <>
            <Header isAuthenticated={isAuthenticated} />
            <div className="your-resumes-container">
                <div className="your-resumes-dropdown">
                    {
                                    
                        showResume && <CustomDropDown 
                            values={Object.keys(userResumes).map((key:string)=>userResumes[key].nickname)}
                            handleSelect={(eventKey)=>{
                                setPresentResume(eventKey);
                            }}
                            label={presentResume}
                        />
                    }
                </div>
                <div className="your-resumes-resume-container">

                    {
                        showResume  ? resume ? <Resume resume={resume} ref={resumeRef} /> : <div> <CustomSpinner /> </div> : 
                        <div className="your-resumes-message">
                            {compMessage}
                            <div>
                                <CustomButton margin="10px 0px 30px 0px" padding="12px " label="Upload Resume" onClick={()=>{navigate("/upload")}} />
                            </div>
                        </div>
                    }
                </div>
                <div className="your-resumes-footer">
                    
                        {
                            isSaved!==null ?( isSaved ?<div className="upload-feedback-container upload-success">
                                <span>Resume Saved Successfully</span>
                            </div>:
                            <div className="upload-feedback-container upload-failure">
                                <span>Error in Saving Resume</span>
                            </div> )
                            :<></>
                        }
                        {
                            showResume && resume && <CustomButton margin="10px 0px 30px 0px" padding="14px " label="Save" onClick={handleSave} />
                        }
                   
                </div>
                
            </div>
        </>
    );
}

export default YourResumes;


