import React from "react";

interface EditIconProps {
    color?: string;
    height?: string;
    width?: string;
}

const EditIcon: React.FC<EditIconProps>= ({color,height,width}) => {

    const defaultColor=color || "black";
    const defaultHeight=height || "24px";
    const defaultWidth=width || "24px";

    return <>
        <svg width={defaultWidth} height={defaultHeight} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.2601 3.59997L5.0501 12.29C4.7401 12.62 4.4401 13.27 4.3801 13.72L4.0101 16.96C3.8801 18.13 4.7201 18.93 5.8801 18.73L9.1001 18.18C9.5501 18.1 10.1801 17.77 10.4901 17.43L18.7001 8.73997C20.1201 7.23997 20.7601 5.52997 18.5501 3.43997C16.3501 1.36997 14.6801 2.09997 13.2601 3.59997Z" stroke={defaultColor} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.8899 5.04999C12.3199 7.80999 14.5599 9.91999 17.3399 10.2" stroke={defaultColor} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 22H21" stroke={defaultColor} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </>
} 

export default EditIcon;

