import React from "react";

interface DownloadIconProps {
    color?: string;
}


const DownloadIcon: React.FC<DownloadIconProps> = ({color}) => {

    const defaultColor=color || "black";
    
    return <>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.44 8.90002C20.04 9.21002 21.51 11.06 21.51 15.11V15.24C21.51 19.71 19.72 21.5 15.25 21.5H8.73998C4.26998 21.5 2.47998 19.71 2.47998 15.24V15.11C2.47998 11.09 3.92998 9.24002 7.46998 8.91002" stroke={defaultColor} strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round"/>
            <path d="M12 2V14.88" stroke={defaultColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.35 12.65L12 16L8.65002 12.65" stroke={defaultColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>


    </>
}

export default DownloadIcon;


