import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { UserResume } from '../utils/_interfaces/Resume';
import { InitialResume } from '../utils/_interfaces/Resume';

interface Model{
    organization:string,
    model:string,
}

interface ResumeKeys{
    file_name:string,
    nickname:string
    url?:string,
    resume?:UserResume,
    isDefault?:boolean
}


interface UserState {
    name:string,
    email:string,
    models:Model[],
    actions:string[],
    tokensLeft:number,
    defaultModel:Model,
    defaultResume:string,
    isAuthenticated:boolean,
    generatedResume:UserResume,
    userResumes:Record<string,ResumeKeys>
}

const initialState:UserState = {
    name:'',
    email:'',
    models:[],
    actions:[],
    tokensLeft:0,
    defaultModel:{
        organization:'',
        model:'',
    },
    defaultResume:"",
    isAuthenticated:false,
    generatedResume:InitialResume,
    userResumes:{}
}



const userSlice = createSlice({
    name:'user',
    initialState,
    reducers:{
        login(state, action:PayloadAction<{name:string,isAuthenticated:boolean,email:string,models:Model[],actions:string[],defaultResume:string,defaultModel:Model,tokensLeft:number,userResumes:Record<string,ResumeKeys>}>) {
            state.name = action.payload.name;
            state.email = action.payload.email;
            state.isAuthenticated = true;
            state.models = action.payload.models;
            state.actions = action.payload.actions;
            state.defaultModel = action.payload.defaultModel;
            state.defaultResume = action.payload.defaultResume;
            state.tokensLeft = action.payload.tokensLeft;
            state.userResumes=action.payload.userResumes;
        },
        logout(state){
            state.isAuthenticated = false;
        },
        updateTokensLeft(state, action:PayloadAction<number>){
            state.tokensLeft = action.payload;
        },
        saveUserResume(state, action:PayloadAction<{key:string,resume:UserResume}>){
            state.userResumes[action.payload.key].resume = action.payload.resume;
        },
        saveNewUserResume(state, action:PayloadAction<{key:string,resume:UserResume,nickname:string,filename:string}>){
            state.userResumes[action.payload.key]={
                file_name:action.payload.filename,
                nickname:action.payload.nickname,
                resume:action.payload.resume
            }
        },
        setActions(state, action:PayloadAction<string[]>){
            state.actions = action.payload;
        },
        setTokensLeft(state,action:PayloadAction<{operation:string,amount:number}>){
            if(action.payload.operation==="add"){
                state.tokensLeft+=action.payload.amount;
            }else{
                state.tokensLeft-=action.payload.amount;
            }
        },
        setGeneratedResume(state,action:PayloadAction<UserResume>){
            state.generatedResume = action.payload;
        },
        setResumeNickname(state,action:PayloadAction<{key:string,nickname:string}>){
            state.userResumes[action.payload.key].nickname=action.payload.nickname;
        },
        setUserResumeURL(state,action:PayloadAction<{key:string,url:string}>){
            state.userResumes[action.payload.key].url=action.payload.url;
        },
        setDefaultResume(state,action:PayloadAction<string>){
            state.defaultResume = action.payload;
        },
        removeUserResume(state,action:PayloadAction<string>){
            delete state.userResumes[action.payload];
        }
    }
});

export const {
    login, 
    logout,
    updateTokensLeft,
    saveUserResume,
    saveNewUserResume,
    setActions,
    setTokensLeft,
    setGeneratedResume,
    setResumeNickname,
    setUserResumeURL,
    setDefaultResume,
    removeUserResume
} = userSlice.actions;
export default userSlice.reducer;

