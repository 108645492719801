import axios, { AxiosResponse } from "axios";
import { GetTokenFunction,SaveTokenFunction } from "../../utils/_interfaces/utils";
import { UserResume } from "../../utils/_interfaces/Resume";


interface Response extends AxiosResponse{
    message:string;
    variantType?:string;
}

enum STATUS_CODES{
    OK=200,
    BAD_REQUEST=400,
    UNAUTHORIZED=401,
    PAYMENT_REQUIRED=402,
    FORBIDDEN=403,
    NOT_FOUND=404,
    USER_EXISTS=409,
    INTERNAL_SERVER_ERROR=500,
    BAD_GATEWAY=502
}

class API{
    private domain: string = process.env.REACT_APP_API_DOMAIN || '';
    private apiClient=axios.create({
        baseURL:this.domain,
    });
    private accessToken:string="";
    private refreshToken:string="";
    private getToken:GetTokenFunction=()=>null;
    private saveToken:SaveTokenFunction=()=>{};
    


    constructor(){
        

        const apiClient=axios.create({
            baseURL:this.domain,
        });

        this.apiClient=apiClient;

        this.apiClient.interceptors.request.use(
            (config)=>{
                const accessToken = this.accessToken; // Replace with your actual token retrieval logic
                if (accessToken) {
                    config.headers.Authorization = `Bearer ${accessToken}`;
                }
                // console.log("Request : ", config);
                return config;
            },
            (error)=>{
                // console.error("Request Error : ",error);
                return Promise.reject(error);
            }
        );

        this.apiClient.interceptors.response.use(
            (response) => response, // Pass through if response is successful
            async (error) => {
                const originalRequest = error.config;

                // console.log("Error : ",error);
                // console.log("Original Request : ",originalRequest);
            
                // Check if error is due to token expiration and if we haven't already retried
                if (error.response?.status === 401 && !originalRequest._retry && !originalRequest.url?.includes("/auth/refresh")) {
                    originalRequest._retry = true; // Mark this request as retried

                    console.log("Refreshing Token");
            
                    const refreshToken = this.refreshToken;
                    console.log("Refresh token : ",refreshToken);
                    if (refreshToken) {
                        try {
                            console.log("Refresh token : ",refreshToken);
                            // Attempt to refresh the token
                            this.setAccessToken(refreshToken);
                            const response = await this.apiClient.post('/auth/refresh');

                            console.log("Response : ",response);
                
                            const accessToken  = response.data.access_token;
                            
                            this.setAccessToken(accessToken);
                            this.accessToken=accessToken;
                            this.saveToken(accessToken, refreshToken);
                            
                
                            // // Retry the original request with the new token
                            // originalRequest.headers.Authorization = `Bearer ${accessToken}`;
                            return apiClient(originalRequest);
                        } catch (refreshError) {
                            console.error('Failed to refresh token', refreshError);
                            // Optionally, clear tokens and redirect to login page
                            localStorage.clear();
                        }
                    } else {
                        console.warn('No refresh token available');
                        // Optionally, redirect to login page

                    
                    }
                }
          
                return Promise.reject(error);
            }
        );
    }

    private request=async (url:string, data:object,headers?:object):Promise<AxiosResponse>=>{

        const response=await this.apiClient.post(url, data)
        .catch(err=>err.response);

        return response;
    }

    public setAccessToken=(accessToken:string):void=>{
        this.apiClient.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

        this.apiClient.interceptors.request.use(
            (config)=>{
                if (accessToken) {
                    config.headers.Authorization = `Bearer ${accessToken}`;
                }
                // console.log("Request : ", config);
                return config;
            },
            (error)=>{
                // console.error("Request Error : ",error);
                return Promise.reject(error);
            }
        );
    }

    public setRefreshToken=(refreshToken:string):void=>{
        this.refreshToken=refreshToken;
    }

    public removeAuthHeader=():void=>{
        delete this.apiClient.defaults.headers.common['Authorization'];
    }

    public setTokenCallback=(getToken:GetTokenFunction,setToken:SaveTokenFunction):void=>{
        console.log("Setting Token Callback");
        this.getToken=getToken;
        this.saveToken=setToken;
    }

    private returnResponse=(response:AxiosResponse):Response=>{
        switch(response.status){
            case STATUS_CODES.OK:
                return {
                    ...response,
                    message:response.data.message,
                    variantType:"success"
                };

            case STATUS_CODES.BAD_REQUEST:
                return {
                    ...response,
                    message:response.data.message,
                    variantType:"danger"
                };

            case STATUS_CODES.UNAUTHORIZED:
                return {
                    ...response,
                    message:response.data.message,
                    variantType:"danger"
                };

            case STATUS_CODES.NOT_FOUND:
                return {
                    ...response,
                    message:response.data.message,
                    variantType:"danger"
                };

            case STATUS_CODES.INTERNAL_SERVER_ERROR:
                return {
                    ...response,
                    message:response.data.message,
                    variantType:"warning"
                };

            case STATUS_CODES.BAD_GATEWAY:
                return {
                    ...response,
                    message:response.data.message,
                    variantType:"warning"
                };
            
            case STATUS_CODES.PAYMENT_REQUIRED:
                return {
                    ...response,
                    message:response.data.message,
                    variantType:"warning"
                };
            
            case STATUS_CODES.USER_EXISTS:
                return {
                    ...response,
                    message:response.data.message,
                    variantType:"danger"
                };
            
            case STATUS_CODES.FORBIDDEN:
                return {
                    ...response,
                    message:response.data.message,
                    variantType:"danger"
                };
                
            default:
                return {
                    ...response,
                    message:response.data.message
                };
                
        }
        
        
    }

    public login=async (email:string, password:string):Promise<Response>=>{
        const response:AxiosResponse = await this.request( "auth/login", {email, password}) as AxiosResponse;
        // console.log("Response : ",response);
        return this.returnResponse(response);
    }

    public googleLogin=async (obj:any):Promise<Response>=>{
        const response:AxiosResponse = await this.request("auth/google_login",{
            "client_id":obj.clientId,
            "token":obj.credential,
            "select_by":obj.select_by,
        }) as AxiosResponse;

        return this.returnResponse(response);
    }

    public signup=async (name:string,email:string, password:string):Promise<Response>=>{
        const response:AxiosResponse = await this.request("auth/signup", {name,email, password}) as AxiosResponse;

        return this.returnResponse(response);
    }

    public googleSignup=async (obj:any):Promise<Response>=>{
        const response:AxiosResponse = await this.request("auth/google_signup",{
            "client_id":obj.clientId,
            "token":obj.credential,
            "select_by":obj.select_by,
        }) as AxiosResponse;

        return this.returnResponse(response);
    }

    public verify=async ():Promise<Response>=>{
        const response:AxiosResponse=await this.request("auth/verify",{})

        return this.returnResponse(response);
    }

    public uploadResume=async (file:File):Promise<Response>=>{
        const formData = new FormData();
        formData.append('file', file);
        let response:AxiosResponse
        
        response=await this.request("/parse", formData,{
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }) as AxiosResponse;

        
        // const response:AxiosResponse = await this.request("/parse", formData) as AxiosResponse;

        console.log("Response : ",response);
        return this.returnResponse(response);
    }

    public generate_resume=async (jd:string,actions:string[]):Promise<Response>=>{

        const response:AxiosResponse = await this.request("/generate", {
            job_description: jd,
            actions
        },{
            headers: {
                'Content-Type': 'application/json',
            }
        }) as AxiosResponse;

        return this.returnResponse(response);
    }
    

    public refresh_tokens=async ():Promise<Response>=>{
        const response:AxiosResponse = await this.apiClient.post("/user/fetch_tokens_left",{}) as AxiosResponse;

        return this.returnResponse(response);
    }

    public update_actions=async (actions:Array<string>):Promise<Response>=>{
        const response:AxiosResponse = await this.request("/user/update_actions", {actions}) as AxiosResponse;

        return this.returnResponse(response);
    }

    public update_default_model=async (organization:string,model:string):Promise<Response>=>{
        const response:AxiosResponse = await this.request("/user/update_default_model", {organization,model}) as AxiosResponse;

        return this.returnResponse(response);
    }

    public saveResume=async (resume:UserResume,key:string):Promise<Response>=>{
        const response:AxiosResponse = await this.request("/user/save_resume", {resume,key}) as AxiosResponse;

        return this.returnResponse(response)
    }

    public getNewResume=async (resume:UserResume):Promise<Response>=>{
        const response:AxiosResponse = await this.request("/generate/new", {resume}) as AxiosResponse;

        return this.returnResponse(response);
    }

    public search_jobs=async (query:string):Promise<Response>=>{
        const response:AxiosResponse = await this.request("/jobs/search", {query}) as AxiosResponse;
        console.log("Response : ",response);
        return this.returnResponse(response);
    }

    public sendSupportMessage=async (email:string,message:string,):Promise<Response>=>{
        const response:AxiosResponse = await this.request("/support", {email,message}) as AxiosResponse;

        return this.returnResponse(response);
    }

    public sendFeedback=async (feedback:string,email?:string):Promise<Response>=>{
        const response:AxiosResponse = await this.request("/feedback", {feedback,email}) as AxiosResponse;

        return this.returnResponse(response);
    }

    public changeNickname=async (key:string,nickname:string):Promise<Response>=>{
        const response:AxiosResponse = await this.request("/user/change_nickname", {key,nickname}) as AxiosResponse;

        return this.returnResponse(response);
    }

    public getResume=async (key:string):Promise<Response>=>{
        const response:AxiosResponse = await this.request("/user/fetch_resume_url", {key}) as AxiosResponse;

        return this.returnResponse(response);
    }

    public getResumeJSON=async (key:string):Promise<Response>=>{
        const response:AxiosResponse = await this.request("/user/fetch_resume", {key}) as AxiosResponse;

        return this.returnResponse(response);
    }

    public removeResume=async (key:string):Promise<Response>=>{
        const response:AxiosResponse = await this.request("/user/remove_resume", {key}) as AxiosResponse;

        return this.returnResponse(response);
    }

    public setDefaultResume=async (key:string):Promise<Response>=>{
        const response:AxiosResponse = await this.request("/user/set_default_resume", {key}) as AxiosResponse;

        return this.returnResponse(response);
    }

    public saveToYourResumes=async (resume:UserResume):Promise<Response>=>{
        const response:AxiosResponse = await this.request("/user/save_to_your_resumes", {resume}) as AxiosResponse;

        return this.returnResponse(response);
    }

    public createNewResume=async (resume:UserResume,filename:string,nickname:string):Promise<Response>=>{
        const response:AxiosResponse = await this.request("/user/create_new_resume", {resume,filename,nickname}) as AxiosResponse;

        return this.returnResponse(response);
    }

    


}

const api = new API();
export default api;