import React,{useState} from "react";
import "./Contact.css";

import Form from 'react-bootstrap/Form';
import CustomButton from "../../utils/Custom/Button/CustomButton";
import Dialog from "../../utils/Custom/Dialog/Dialog";
import api from "../../managers/api/api";
import Header from "../Header/Header";

import { useAppSelector } from "../../store/hooks";


interface ContactInputs{
    email:string;
    message:string;
}

const Contact: React.FC = () => {

    const email=useAppSelector((state)=>state.user.email);
    const [input, setInput] = useState<ContactInputs>({
        email:email,
        message: ""
    });

    // console.log("Email : ",email);



    const [dialogArgs, setDialogArgs] = useState({
        show: false,
        message: "",
        variantType: ""
    });

    const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);
    // const email=useAppSelector((state)=>state.user.email);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        });
    }


    const handleSupport = async() => {
        
        const response=await api.sendSupportMessage(input.email, input.message);

        if(response.status===200){
            setDialogArgs({
                show: true,
                message: response.message,
                variantType: "success"
            });
        }else{
            setDialogArgs({
                show: true,
                message: response.message,
                variantType: "danger"
            });
        }
    }

    


    return (
        <>
            <Header isAuthenticated={isAuthenticated}/>
            <div className="support-container">
                <div className="support-header">
                    <h1>Contact</h1>
                </div>
                
                <div className="support-form">
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control 
                                type="email" 
                                placeholder="Enter your email"
                                value={input.email}
                                name="email"
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Message</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                rows={3} 
                                name="message"
                                value={input.message}
                                placeholder="Enter your message"
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Dialog 
                            show={dialogArgs.show}
                            message={dialogArgs.message}
                            variantType={dialogArgs.variantType}
                        />
                        <CustomButton label="Submit" onClick={handleSupport}/>
                    </Form>
                </div>
                
            </div>
        </>
    );
}

export default Contact;


