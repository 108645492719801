import React, { useEffect } from "react";

import { useAppDispatch } from "../../store/hooks";
import { useNavigate } from "react-router-dom";
import api from "../../managers/api/api";

import { logout } from "../../store/UserSlice";
import { clearTokens } from "../../store/AppSlice";
const Logout: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");

    useEffect(() => {
        navigate("/");
        dispatch(logout())
        dispatch(clearTokens())
        api.removeAuthHeader()
    },[navigate,dispatch]);
    

    return (
        <></>
    );
}

export default Logout;
