import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { login } from "../../store/UserSlice";

import api from "../../managers/api/api";
// import { useAppSelector } from "../../store/hooks";
import { setTokens } from "../../store/AppSlice";

const useAuthHandler = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    // const app=useAppSelector((state)=>state.app);

    // console.log("App : ",app);


    const handleAuth=async (response:any)=>{
        if(response.status===200){
            const accessToken=response.data.access_token;
            const refreshToken=response.data.refresh_token;
            // console.log("Access Token : ",accessToken,refreshToken);
            api.setAccessToken(accessToken);
            
            const verifyResponse=await api.verify();
            // console.log("Verify Response : ",verifyResponse);
            if(verifyResponse.status===200){
                // console.log(verifyResponse.data);
                dispatch(login({
                    isAuthenticated: true,
                    // ...verifyResponse.data
                    name: verifyResponse.data.name,
                    email: verifyResponse.data.email,
                    models: verifyResponse.data.models,
                    actions: verifyResponse.data.actions,
                    defaultModel: verifyResponse.data.default_model,
                    tokensLeft: verifyResponse.data.tokens_left,
                    userResumes:verifyResponse.data.user_resumes,
                    defaultResume:verifyResponse.data.default_resume
                }));
                setTokens({accessToken,refreshToken});
                localStorage.setItem("accessToken",accessToken);
                localStorage.setItem("refreshToken",refreshToken);
                navigate("/home");
            }else{
                window.location.href="/login";
            }
            // dispatch(setTokens({accessToken,refreshToken}));
        }else{
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            navigate("/login");
        }
    }

    return {handleAuth}

}

export default useAuthHandler;

