import React from "react";


interface RemoveIconProps {
    color?: string;
}

const RemoveIcon:React.FC<RemoveIconProps> = ({color}) => {
    const defaultcolor = color || "#292D32";

    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.92 12.75H7.92C7.51 12.75 7.17 12.41 7.17 12C7.17 11.59 7.51 11.25 7.92 11.25H15.92C16.33 11.25 16.67 11.59 16.67 12C16.67 12.41 16.34 12.75 15.92 12.75Z" fill={defaultcolor}/>
            </svg>
        </>
    )
}

export default RemoveIcon
