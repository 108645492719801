import React,{useState} from "react";
import "./Login.css";

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Dialog from "../../utils/Custom/Dialog/Dialog";

import GoogleAuthComp from "../GoogleAuthComp/GoogleAuthComp";

import useAuthHandler from "../../utils/hooks/useAuthHandler";
import api from "../../managers/api/api";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../utils/Custom/Button/CustomButton";



const Login: React.FC = () => {

    
    const {handleAuth }= useAuthHandler();
    const navigate=useNavigate();


    const [inputs, setInputs] = useState({ email: '', password: '' });
    const [validated, setValidated] = useState<boolean>(false);
    const [feedback,setFeedback]=useState<string>("");
    const [showDialog,setShowDialog]=useState<boolean>(false);
    const [variantType,setVariantType]=useState<string>("danger");

    const handleSubmit = async (event:any) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        setValidated(true);

        const response=await api.login(inputs.email, inputs.password);
        // console.log("Login Response : ",response);

        setFeedback(response.message);
        setShowDialog(true);
        setVariantType(response.variantType || "danger");
        
        handleAuth(response);   
    
    };

    return (
        <div className="login-container">
            <div className="login-container-header">
                <h1>Login</h1>
            </div>
            <div className="login-container-body">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Email address"
                        className="mb-3"
                    >
                        <Form.Control 
                            required 
                            type="email" 
                            placeholder="name@example.com" 
                            onChange={(e) => setInputs({...inputs, email: e.target.value})}
                        />
                        <Form.Control.Feedback type="invalid">
                            Enter Email
                        </Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingPassword" label="Password">
                        <Form.Control 
                            required 
                            type="password" 
                            placeholder="Password" 
                            onChange={(e) => setInputs({...inputs, password: e.target.value})}
                        />
                        <Form.Control.Feedback type="invalid">
                            Enter Password
                        </Form.Control.Feedback>
                    </FloatingLabel>
                    <Dialog
                        show={showDialog}
                        message={feedback}
                        variantType={variantType}
                    />
                    <CustomButton
                        type="submit"
                        label="Login"
                        margin="30px 0px 0px 0px"
                        padding="10px 20px"
                    />
                </Form>
            </div>
            <div className="login-container-footer">
                <p onClick={()=>navigate('/signup')}>Don't have an account?</p>
            </div>

            <GoogleAuthComp requestType="login"/>

        </div>
    );
}

export default Login;