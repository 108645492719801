import React from "react";
import "./CustomDropDown.css";
import Dropdown from 'react-bootstrap/Dropdown';

interface CustomDropDownProps {
    values: string[];
    handleSelect: (eventKey: string ) => void;
    label: string;
}

const CustomDropDown: React.FC<CustomDropDownProps> = ({values,handleSelect,label}) => {
    return (
        <Dropdown 
            onSelect={(eventKey) => handleSelect(eventKey || "")}
            style={{
                backgroundColor: "var(--primary-color)",
                color: "white",
                fontWeight: "bold",
                borderRadius: "0.5rem",
            }}
        >
            <Dropdown.Toggle id="dropdown-basic">
                {label}
            </Dropdown.Toggle>
    
            <Dropdown.Menu>
                {
                    values.map((value, index) => {
                        return (
                            <Dropdown.Item key={index} eventKey={value}>{value}</Dropdown.Item>
                        )
                    })

                }
            </Dropdown.Menu>
      </Dropdown>
    );
}

export default CustomDropDown;

