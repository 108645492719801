import React,{useState} from "react";
import "./Creds.css";
import CustomSpinner from "../../utils/Custom/Spinner/CustomSpinner";
import Header from "../Header/Header";

import { useAppSelector } from "../../store/hooks";

const Creds: React.FC = () => {

    const [isLoaded, setIsLoaded] = useState(false);
    const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);


    setTimeout(() => {
        setIsLoaded(true);
    }, 2000);

    return (
        <>
            <div className="creds-container">
                <Header isAuthenticated={isAuthenticated} />
                {
                    isLoaded ? (
                        <div className="creds-content">
                            <h1>Credentials</h1>
                            <div className="creds-content__item">
                                <h3>Username</h3>
                                <p>username</p>
                            </div>
                            <div className="creds-content__item">
                                <h3>Password</h3>
                                <p>password</p>
                            </div>
                        </div>
                    ) : (
                        <div className="creds-loading-container">
                            <CustomSpinner />
                        </div>
                        
                    )
                }
                
            </div>
        </>
    );
}

export default Creds;



