import React from "react";
import { Spinner } from "react-bootstrap";

const CustomSpinner: React.FC = () => {
    return (
        <Spinner animation="border" color="var(--primary-color)" role="status" />
    );
}

export default CustomSpinner;


