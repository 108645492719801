import React, { useEffect } from "react";
import "./Header.css";

import { useLocation } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import ProfileIcon from "../../icons/ProfileIcon";

interface HeaderProps {
    isAuthenticated: boolean;
}

const Header: React.FC<HeaderProps> = ({isAuthenticated}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname.split('/').filter(Boolean)[0];
    // console.log("Path: ", path);

    const handleDropdownClick = () => {
        const element=document.getElementById('header-dropdown-content');
        if(element){
            if(element.classList.contains('show')){
                element.classList.remove('show');
            }else{
                element.classList.add('show');
            }
        }
    }

    useEffect(() => {
        const element=document.getElementById(`header-${path}-btn`);
        if(element){
            element.style.backgroundColor="var(--primary-color)";
            element.style.color="white";
        }

    }, [path]);

    return (
        <div className="header-container">
            <div className="header-logo-container" onClick={()=>navigate("/home")}>
                <img src="./logo512_.png" alt="logo" className="header-logo" />
            </div>
            <div className="header">
            {
                    isAuthenticated ? (
                        <>
                            <div className="header-login" id="header-login">
                                <div className="header-dropdown">
                                    <div className="header-profile-icon" onClick={()=>handleDropdownClick()}>
                                        <ProfileIcon />
                                    </div>
                                    
                                    <div className="header-dropdown-content" id="header-dropdown-content">
                                        <div className="header-dropdown-item" onClick={()=>navigate("/account")}>Account</div>
                                        <div className="header-dropdown-item" onClick={()=>navigate("/feedback")}>Feedback</div>
                                        <div className="header-dropdown-item" onClick={()=>navigate("/logout")}>Logout</div>

                                    </div>
                                </div>

                                    
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="header-login">
                                <button className="header-btns" onClick={() => navigate("/login")} id="header-login-btn">Login</button>
                            </div>
                        
                        </>
                    )
                }
            </div>
        </div>
    );
}

export default Header;




