import React from "react";
import "./CustomBadge.css";


interface CustomBadgeProps {
    onClick?: () => void;
    label:string;
}

const CustomBadge:React.FC<CustomBadgeProps> = ({
    label,
    onClick,
}) => {
    return (
        <div className="my-custom-badge">
            {label}
            <span className="my-badge-close" onClick={onClick}>X</span>
        </div>
    );
}

export default CustomBadge;

