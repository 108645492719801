import React from "react";

interface SendIconProps {
    color?: string;
}

const SendIcon: React.FC<SendIconProps> = ({color}) => {

    const defaultColor = color ||  "#000000"; 

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.39993 6.31991L15.8899 3.48991C19.6999 2.21991 21.7699 4.29991 20.5099 8.10991L17.6799 16.5999C15.7799 22.3099 12.6599 22.3099 10.7599 16.5999L9.91993 14.0799L7.39993 13.2399C1.68993 11.3399 1.68993 8.22991 7.39993 6.31991Z" stroke={defaultColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.1101 13.6501L13.6901 10.0601" stroke={defaultColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
}

export default SendIcon;




