import React from "react";
import "./Generate.css";

import { Spinner } from "react-bootstrap";

import Header from "../Header/Header";
import EditableContent from "../EditableContent/EditableContent";
import PdfViewer from "../PdfViewer/PdfViewer";

import { useAppSelector } from "../../store/hooks";
import { useAppDispatch } from "../../store/hooks";
import { setResumeURL } from "../../store/AppSlice";
import { setActions,setTokensLeft, setGeneratedResume} from "../../store/UserSlice";
import { actionsList } from "../../utils/Constants/Actions";
import api from "../../managers/api/api"

const Generate: React.FC = () => {

    const dispatch=useAppDispatch();
    const pdfUrl = useAppSelector((state) => state.app.resumeURL);

    const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);
    
    const jd = useAppSelector((state) => state.app.jobDescription);
    const priorActions=useAppSelector((state)=>state.user.actions);

    const [generating, setGenerating]=React.useState<boolean>(false);
    const [generated, setGenerated]=React.useState<boolean>(false);
    const [generatedFeedback, setGeneratedFeedback]=React.useState<string>("No preview available");
    
    const handleClick=async ()=>{
        setGenerated(false);
        setGenerating(true);
        dispatch(setResumeURL(''));
        
        const response=await api.generate_resume(jd,priorActions);

        console.log("response : ",response);

        setGeneratedFeedback(response.message);
        setGenerating(false);
        if(response.status!==200){
            return
        }

        const usage=response.data.usage;
        const resume=response.data.resume;
        const generatedResume=response.data.generatedResume;

        const blob = new Blob([Uint8Array.from(atob(resume), (c) => c.charCodeAt(0))], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        // console.log("url : ",url);
        dispatch(setTokensLeft({operation:"subtract",amount:usage}));
        dispatch(setResumeURL(url));
        dispatch(setGeneratedResume(generatedResume));
        setGenerating(false);
        setGenerated(true)

        console.log("generated response : ",response);

    }

    const handleActionClick=(e:any)=>{
        e.preventDefault();

        const action=e.target.innerText;
        if(priorActions.includes(action)){
            dispatch(setActions(priorActions.filter((prevAction)=>prevAction!==action)));
            return;
        }
        dispatch(setActions(Array.from(new Set([...priorActions,action]))));
    }

    return (
        <div className="generate-container">
            <div className="generate-container-header">
                <Header isAuthenticated={isAuthenticated} />
            </div>
            <div className="generate-container-btn-section">
                <div className="generate-container-action-btns">
                    <span className="generate-container-section-title">Choose what sections to update ...</span>
                    <div className="generate-container-actions-container">
                        {
                            actionsList.map((action,index)=>{
                                return (
                                    <div key={index} className={priorActions.includes(action)?"generate-container-action-selected generate-container-action-btn":"generate-container-action generate-container-action-btn"} onClick={handleActionClick}>
                                        {action}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="generate-container-result-btns">
                    <button id='generate-btn' onClick={handleClick}>Generate</button>
                </div>
            </div>
            <div className="generate-container-body">
                <div className="generate-item">
                    <EditableContent />
                </div>
                <div className="generate-item">
                    {
                        generating?
                        <div className="generating-loader"><Spinner  /></div>
                        :
                        generated || pdfUrl!==""? <>
                            <PdfViewer pdfUrl={pdfUrl} />
                            {/* <iframe id="resume-frame" title={fileName} src={pdfUrl} width="100%" height="97%"></iframe>  */}
                            
                        
                        </>:
                        <div className="generating-loader">{generatedFeedback}</div>
                    }

                </div>
            </div>
            
        </div>
    );
}

export default Generate;


