import { createSlice,PayloadAction } from "@reduxjs/toolkit";

interface AppState{
    resumeURL:string,
    jobDescription:string,
    accessToken:string,
    refreshToken:string,
}

const initialState:AppState = {
    resumeURL:'',
    jobDescription:'',
    accessToken:'',
    refreshToken:'',
}

const appSlice = createSlice({
    name:'app',
    initialState,
    reducers:{
        setResumeURL(state, action:PayloadAction<string>){
            state.resumeURL = action.payload;
        },
        setJobDescription(state, action:PayloadAction<string>){
            state.jobDescription = action.payload;
        },
        setTokens(state, action:PayloadAction<{accessToken:string,refreshToken:string}>){
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
        },
        clearTokens(state){
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            state.accessToken = '';
            state.refreshToken = '';
        }
    }
});

export const { setResumeURL,setJobDescription,setTokens,clearTokens } = appSlice.actions;
export default appSlice.reducer;