import React from 'react'

import { GoogleLogin ,GoogleOAuthProvider} from "@react-oauth/google";
import useAuthHandler from "../../utils/hooks/useAuthHandler";
import api from "../../managers/api/api";

interface GoogleAuthCompProps {
    requestType: "login" | "signup";
}

const GoogleAuthComp:React.FC<GoogleAuthCompProps>= ({requestType}) => {
    const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || "826676846388-tau9p8i48mnullj753e5pvc328n8ovq0.apps.googleusercontent.com";
    // console.log("google client id : ",CLIENT_ID);
    const {handleAuth }= useAuthHandler();


    const handleGoogleLogin = async (response:any) => {
        // console.log("google login response : ",response);

        const loginResponse=await api.googleLogin(response);

        handleAuth(loginResponse);
    }

    const handleGoogleSignup = async (response:any) => {
        // console.log("google signup response : ",response);

        const signupResponse=await api.googleSignup(response);
        handleAuth(signupResponse);

    }


    return (
        <>
            <div>
                <GoogleOAuthProvider clientId={CLIENT_ID}>
                    <GoogleLogin
                        onSuccess={(response) =>{
                            // console.log("google login response : ",response);
                            if(requestType==="login"){
                                handleGoogleLogin(response);
                            }else if(requestType==="signup"){
                                handleGoogleSignup(response);
                            }
                        }}
                        onError={() => console.log("error")}
                    />
                </GoogleOAuthProvider>
            </div>
        
        </>
    )
}

export default GoogleAuthComp

