import React, { useEffect } from "react";
import "./LandingPage.css";


import useAuthHandler from "../../utils/hooks/useAuthHandler";
import Header from "../Header/Header";

const LandingPage: React.FC = () => {

    const {handleAuth} = useAuthHandler();
    const accessToken=localStorage.getItem("accessToken");
    const refreshToken=localStorage.getItem("refreshToken");


    useEffect(()=>{
        // console.log("LandingPage accessToken: ", accessToken);
        if(accessToken && refreshToken){
            handleAuth({status:200,data:{access_token:accessToken,refresh_token:refreshToken}});
        }
        
    },[handleAuth,accessToken,refreshToken]);

    return (
        <>
            <Header isAuthenticated={false} />
            <div className="landing-page-container">
                <div className="landing-page-item">
                    <h4>
                        Welcome to Xpress CV
                    </h4>
                    <span>Create Resumes tailored to job descriptions in seconds.</span>
                </div>
                <div className="landing-page-item">
                    <h4>
                        Why Xpress CV?
                    </h4>
                    <span>Save time by generating resumes in seconds.</span>
                    <span>Get more interviews by tailoring your resume to job descriptions.</span>
                </div>
                <div className="landing-page-item">
                    <h4>
                        How it works?
                    </h4>
                    <span>Step-1 : Upload and save your Resume. </span>
                    <span>Step-2 : Choose actions to perform.</span>
                    <span>Step-3 : Paste job description and get your resume in pdf. </span>
                </div>
                
            </div>
        </>
    );
}

export default LandingPage;



