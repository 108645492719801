import React from "react";
import "./CustomSticker.css";

interface CustomStickerProps {
    label:string;
    color:string;
    fontSize?:string;
    padding?:string;
    margin?:string;
}

const CustomSticker:React.FC<CustomStickerProps> = ({label,color,fontSize,padding,margin}) => {
    const size=fontSize || "0.9rem";
    const pad=padding || "0.2rem 0.5rem";
    const marg=margin || "2px";
    return (
        <span
            className="my-custom-sticker"
            style={{
                backgroundColor:color,
                color:"#fff",
                fontSize:size,
                fontWeight:"bolder",
                padding:pad,
                margin:marg,
            }}
        >{label}</span>
    )
}

export default CustomSticker


