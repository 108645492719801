import React,{useState} from "react";
import "./Feedback.css";

import Form  from "react-bootstrap/Form";
import Dialog from "../../utils/Custom/Dialog/Dialog";
import CustomButton from "../../utils/Custom/Button/CustomButton";
import { useAppSelector } from "../../store/hooks";
import api from "../../managers/api/api";
import Header from "../Header/Header";

const Feedback: React.FC = () => {

    const [inputs, setInputs] = useState({ 
        email: '', 
        sendAnonymously: false,
        feedback: '' 
    });
    const [responseFeedback,setResponseFeedback]=useState<{
        message: string;
        variantType: string;
        showDialog: boolean;
    }>({
        message:"",
        variantType:"",
        showDialog:false
    });

    const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);

    const handleSendFeedback = async () => {

        setResponseFeedback({
            message:"",
            variantType:"",
            showDialog:false
        });

        const response=await api.sendFeedback(inputs.feedback,(inputs.sendAnonymously)?undefined:inputs.email);
        
        console.log("Feedback Response : ",response);

        setResponseFeedback({
            message:response.message,
            variantType:response.variantType || "warning",
            showDialog:true
        });

    }


    return (
        <>
            <Header isAuthenticated={isAuthenticated}/>
            <div className="feedback-container">
                
                <Form className="feedback-form" >
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="feedback-container-labels">Email address</Form.Label>
                        <Form.Control 
                            type="email" 
                            placeholder="Enter email"
                            value={inputs.email}
                            onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
                            required={inputs.sendAnonymously?false:true}
                            disabled={inputs.sendAnonymously}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            onChange={(e) => setInputs({ ...inputs, email:"",sendAnonymously: e.target.checked })}
                            label="Send Anonymously"
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label className="feedback-container-labels">Feedback</Form.Label>
                        <Form.Control 
                            as="textarea" 
                            rows={3} 
                            value={inputs.feedback}
                            onChange={(e) => setInputs({ ...inputs, feedback: e.target.value })}
                        />
                    </Form.Group>
                    <Dialog
                        show={responseFeedback.showDialog}
                        message={responseFeedback.message}
                        variantType={responseFeedback.variantType}
                    />
                    <CustomButton label="Submit" onClick={()=>handleSendFeedback()}/>
                </Form>
            </div>
        
        </>
    );
}

export default Feedback;

