

export interface Education {
    degree: string;
    university: string;
    start: string;
    end: string;
    gpa: string;
}

export interface Experience {
    title: string;
    company: string;
    start: string;
    end: string;
    description: string[];
}

export interface Project {
    name: string;
    ref: string;
    description: string[];
}

export interface Skill {
    category: string;
    technologies: string[];
}

export interface Certification {
    name: string;
    organization: string;
    date: string;
    certificate_number: string;
}

export interface Publication {
    title: string;
    date: string;
    ref: string;
}

export interface UserResume {
    fullName: string;
    email: string;
    phone: string;
    linkedin: string;
    profile: string;
    education: Education[];
    experience: Experience[];
    projects: Project[];
    skills: Skill[];
    certifications: Certification[];
    publications: Publication[];
}

export const InitialResume: UserResume = {
    fullName: '',
    email: '',
    phone: '',
    linkedin: '',
    profile: '',
    education: [{
        degree: '',
        university: '',
        start: '',
        end: '',
        gpa: ""
    }],
    experience: [{
        title: '',
        company: '',
        start: '',
        end: '',
        description:[""]
    }],
    projects: [{
        name: '',
        ref: '',
        description: [""]
    }],
    skills: [{
        category: '',
        technologies: []
    }],
    certifications: [],
    publications: []
}





