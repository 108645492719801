import React  from 'react';
import './EditableContent.css'; // Optional for additional styling

import { useAppSelector } from '../../store/hooks';
import { useAppDispatch } from '../../store/hooks';
import { Form } from 'react-bootstrap';

import { setJobDescription } from '../../store/AppSlice';
interface EditableContentProps {
    // Define props here
}

const EditableContent: React.FC<EditableContentProps> = () => {

    const dispatch = useAppDispatch();
    const jobDescription=useAppSelector((state)=>state.app.jobDescription);
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        // console.log(e.target.value);
        dispatch(setJobDescription(e.target.value));
    }

    return (
        <Form.Control
            as={'textarea'}
            id='editable-content'
            style={{ height: '80%' ,resize: 'none',fontSize: '0.8rem'}}
            value={jobDescription}
            onChange={handleChange}
            placeholder='Enter job description here'
            name="jobDescription"
            className='editable-content'
        />
    );
};

export default EditableContent;