import React, { useImperativeHandle,useEffect, useState} from "react";
import "./Resume.css";


import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';

import CustomButton from "../../utils/Custom/Button/CustomButton";
import CustomBadge from "../../utils/Custom/Badge/CustomBadge";

import { UserResume,Education,Experience,Project,Skill, InitialResume } from "../../utils/_interfaces/Resume";
import RemoveIcon from "../../icons/RemoveIcon";


interface ResumeProps {
    resume:UserResume, 
    ref:any

}

// const ROW_NUMBER=6;


const Resume: React.FC<ResumeProps> = ({resume,ref}) => {

    const [inputs, setInputs] = useState<UserResume>(InitialResume);

    console.log("resume `: ",resume);

    const handleRemoveSection = (e: any,params:{name:string,index:number}) => {
        setInputs(inputs => {
            return {
                ...inputs,
                [params.name]: (inputs[params.name as keyof UserResume] as any[]).filter((item,index)=>index!==params.index)
            }
        })
    }

    const handleChange = (e: any,params?:{name:string,index:number,microIndex?:number}) => {
        if(params){
            // console.log("params : ",params);
            // console.log("name : ",e.target.name);
            console.log("value : ",e.target.value);
            // console.log("inputs `   : ",inputs);
            // console.log("inputs[params.name] : ",inputs[params.name as keyof UserResume][0]);

            if(params.name==="skills"){
                
                setInputs(inputs => {
                    return {
                        ...inputs,
                        [params.name]: (inputs[params.name as keyof UserResume] as any[]).map((item,index)=>{
                            if(index===params.index){
                                return {
                                    ...item,
                                    [e.target.name]: e.target.value
                                }
                            }else{
                                return item;
                            }
                        })
                    }
                })
                return;
            }

            setInputs(inputs => {
                return {
                    ...inputs,
                    [params.name]: (inputs[params.name as keyof UserResume] as any[]).map((item,index)=>{
                        if(index===params.index){
                            if(e.target.name==="description"){
                                if(e.target.value===""){
                                    return {
                                        ...item,
                                        "description":item["description"].filter((subItem:string,subIndex:number)=>subIndex!==params.microIndex)
                                    }
                                }
                                return {
                                    ...item,
                                    "description":item["description"].map((subItem:string,subIndex:number)=>{
                                        if(subIndex===params.microIndex){
                                            return e.target.value
                                        }
                                        return subItem
                                    })
                                }
                            }
                            return {
                                ...item,
                                [e.target.name]: e.target.value
                            }
                        }else{
                            return item;
                        }
                    })
                }
            })

            if(params.name==="experience" || params.name==="projects"){
                setInputs(inputs => {
                    if(params.name==="experience"){
                        if( inputs.experience[params.index].description[inputs.experience[params.index].description.length-1]!==""){
                            return {
                                ...inputs,
                                [params.name]: (inputs[params.name as keyof UserResume] as any[]).map((item,index)=>{
                                    if(index===params.index){
                                        return {
                                            ...item,
                                            "description":[...item["description"],""]
                                        }
                                    }
                                    return item;
                                })
                            }
                        }
                    }
                    if(params.name==="projects"){
                        if(inputs.projects[params.index].description[inputs.projects[params.index].description.length-1]!==""){
                            return {
                                ...inputs,
                                [params.name]: (inputs[params.name as keyof UserResume] as any[]).map((item,index)=>{
                                    if(index===params.index){
                                        return {
                                            ...item,
                                            "description":[...item["description"],""]
                                        }
                                    }
                                    return item;
                                })
                            }
                        }
                    }
                    return inputs;
                })
            }

            return;
        };

        setInputs(inputs => ({
            ...inputs,
            [e.target.name]: e.target.value
        }));

    }


    const handleAddEducation = () => {
        setInputs(inputs => ({
            ...inputs,
            education: [...inputs.education, {
                degree: '',
                university: '',
                start: '',
                end: '',
                gpa: ""
            }]
        }));
    }

    const handleAddExperience = () => {
        setInputs(inputs => ({
            ...inputs,
            experience: [...inputs.experience, {
                title: '',
                company: '',
                start: '',
                end: '',
                description:[""]
            }]
        }));
    }

    const handleAddTechnology = (e: any,params:{skill:string}) => {
        setInputs(inputs => {
            return {
                ...inputs,
                skills: inputs.skills.map((skill)=>{
                    if(skill.category===params.skill){
                        return {
                            ...skill,
                            technologies: [...skill.technologies, e.target.previousSibling.value]
                        }
                    }else{
                        return skill;
                    }
                })
            }
        })
    }

    const handleAddProject = () => {
        setInputs(inputs => ({
            ...inputs,
            projects: [...inputs.projects, {
                name: '',
                ref: '',
                description: [""]
            }]
        }));
    }

    const handleAddSkill = () => {
        setInputs(inputs => ({
            ...inputs,
            skills: [...inputs.skills, {
                category: '',
                technologies: []
            }]
        }));
    }

    const handleRemoveSubSection=(e: any,params:{name:string,index:number,microIndex:number})=>{
        setInputs(inputs => {
            return {
                ...inputs,
                [params.name]: (inputs[params.name as keyof UserResume] as any[]).map((item,index)=>{
                    if(index===params.index){
                        return {
                            ...item,
                            "description":item["description"].filter((subItem:string,subIndex:number)=>subIndex!==params.microIndex)
                        }
                    }else{
                        return item;
                    }
                })
            }
        })
    }
    const removeTechnology = (e: any,params:{category:string,index:number}) => {
        setInputs(inputs => {
            return {
                ...inputs,
                skills: inputs.skills.map((skill)=>{
                    if(skill.category===params.category){
                        return {
                            ...skill,
                            technologies: skill.technologies.filter((tech,index)=>index!==params.index)
                        }
                    }else{
                        return skill;
                    }
                })
            }
        })
    }

    useImperativeHandle(ref, () => ({
        getResume: () => {
            const skills:Skill[]=[]
            inputs["skills"].map((skill)=>{
                if(skill["category"]!==""){
                    skills.push(skill);
                }
                return skill;
            })

            const projects:Project[]=[]
            inputs["projects"].map((proj)=>{
                for(let i=0;i<proj["description"].length;i++){
                    if(proj["description"][i]===""){
                        proj["description"].pop();
                    }
                }
                projects.push(proj);
                return proj;
            })

            const experience:Experience[]=[]
            inputs["experience"].map((exp)=>{
                for(let i=0;i<exp["description"].length;i++){
                    if(exp["description"][i]===""){
                        exp["description"].pop();
                    }
                }
                experience.push(exp);
                return exp;
            })
            
            return {
                ...inputs,
                skills:skills,
                projects:projects,
                experience:experience
            };
        }
    }));

    useEffect(() => {
        // console.log("uploaded resume :  ",resume);
        if(resume){
            setInputs((prev)=>{
                const projects:Project[]=[]
                resume.projects.map((proj: Project, index: number) => {
                    if(proj.description[proj.description.length-1]!==""){
                        projects.push({
                            ...proj,
                            description:[...proj.description,""]
                        });
                        return proj
                    }
                    console.log("proj : ",proj);
                    projects.push(proj);
                    return proj;
                })
                const experience:Experience[]=[]
                resume.experience.map((exp: Experience, index: number) => {
                    if(exp.description[exp.description.length-1]!==""){
                        experience.push({
                            ...exp,
                            description:[...exp.description,""]
                        });
                        return exp
                    }
                    console.log("exp : ",exp);
                    experience.push(exp);
                    return exp;
                })
                console.log("resume : ",resume);
                console.log("updated : ",{
                    ...resume,
                    projects:projects,
                    experience:experience
                })
                console.log("projects : ",projects);
                return {
                    ...resume,
                    projects:projects,
                    experience:experience
                }
            });
        }
    },[resume])

    // useEffect(() => {
    //     console.log("inputs : ",inputs);
    //     const projects:Project[]=[]
    //     inputs.projects.map((proj: Project, index: number) => {
    //         console.log("proj : ",proj);
    //         console.log("proj.description : ",proj.description[proj.description.length-1]);
    //         if(proj.description[proj.description.length-1]!==""){
    //             console.log("pushing");
    //             projects.push({
    //                 ...proj,
    //                 description:[...proj.description,""]
    //             });
    //         }
    //         return proj;
    //     })
    // },[inputs])


    return (
        <div className="resume-container">
            <div className="resume-input-container">
                <Form>
                    <Form.Group controlId="resumeForm.fullName" className="resume-input-group">
                        <Form.Label className="resume-section-title">Full Name</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="John Doe" 
                            name="fullName"
                            value={inputs.fullName}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="resumeForm.email">
                        <Form.Label className="resume-section-title">Email address</Form.Label>
                        <Form.Control 
                            type="email" 
                            placeholder="name@example.com" 
                            name="email"
                            value={inputs.email}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="resumeForm.phone">
                        <Form.Label className="resume-section-title">Phone</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="1234567890" 
                            name="phone"
                            value={inputs.phone}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="resumeForm.linkedin">
                        <Form.Label className="resume-section-title">LinkedIn</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="https://linkedin.com/in/johndoe" 
                            name="linkedin"
                            value={inputs.linkedin}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="resumeForm.profile">
                        <Form.Label className="resume-section-title">Profile (Optional)</Form.Label>
                        <Form.Control 
                            as="textarea" 
                            rows={3} 
                            name="profile"
                            value={inputs.profile}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="resume-section-title">Education</Form.Label>
                        {
                            inputs.education.map((edu: Education, index: number) => (
                                <>
                                    <div key={index} className="resume-section">
                                        <span className="resume-remove-section" onClick={(e)=>handleRemoveSection(e,{name:"education",index:index})}>
                                            <RemoveIcon color="var(--primary-color)"/>
                                        </span>
                                        <Row className="mb-3" key={index}>
                                            <Col>
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="Degree" 
                                                    name="degree"
                                                    value={edu.degree}
                                                    onChange={(e)=>handleChange(e,{name:"education",index:index})}
                                                />
                                            </Col>
                                            
                                            <Col>
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="Start Date" 
                                                    name="start"
                                                    value={edu.start}
                                                    onChange={(e)=>handleChange(e,{name:"education",index:index})}
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="End Date" 
                                                    name="end"
                                                    value={edu.end}
                                                    onChange={(e)=>handleChange(e,{name:"education",index:index})}
                                                />
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col>
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="University" 
                                                    name="university"
                                                    value={edu.university}
                                                    onChange={(e)=>handleChange(e,{name:"education",index:index})}
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Control 
                                                    type="number" 
                                                    placeholder="GPA" 
                                                    name="gpa"
                                                    value={edu.gpa}
                                                    onChange={(e)=>handleChange(e,{name:"education",index:index})}
                                                />
                                            </Col>

                                        </Row>
                                    </div>
                                </>
                            ))
                        }
                        <Row className="mt-3">
                            <CustomButton onClick={handleAddEducation} margin="2px 0px 0px 30px" width={"200px"} padding="4px"  label={"Add Education"}  />
                        </Row>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="resume-section-title">Experience</Form.Label>
                        {
                            inputs.experience.map((exp: Experience, index: number) => {
                                return <>
                                    <div key={index} className="resume-section">
                                        <span className="resume-remove-section" onClick={(e)=>handleRemoveSection(e,{name:"experience",index:index})}>
                                            <RemoveIcon color="var(--primary-color)"/>
                                        </span>
                                        <Row className="mb-3" key={index}>
                                            <Col>
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="Title" 
                                                    name="title"
                                                    value={exp.title}
                                                    onChange={(e)=>handleChange(e,{name:"experience",index:index})}
                                                />
                                            </Col>
                                            
                                            <Col>
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="Start Date" 
                                                    name="start"
                                                    value={exp.start}
                                                    onChange={(e)=>handleChange(e,{name:"experience",index:index})}
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="End Date" 
                                                    name="end"
                                                    value={exp.end}
                                                    onChange={(e)=>handleChange(e,{name:"experience",index:index})}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col>
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="Company" 
                                                    name="company"
                                                    value={exp.company}
                                                    onChange={(e)=>handleChange(e,{name:"experience",index:index})}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col>
                                                {
                                                    inputs.experience[index].description.map((desc: string, descIndex: number) => {
                                                        return <>
                                                            <Row className="m-1">
                                                                <div key={index} className="resume-sub-section">
                                                                    
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter Description"
                                                                        value={desc}
                                                                        name="description"
                                                                        onChange={(e)=>handleChange(e,{name:"experience",index:index,microIndex:descIndex})}
                                                                    />
                                                                    <span className="resume-remove-sub-section" onClick={(e)=>handleRemoveSubSection(e,{name:"experience",index:index,microIndex:descIndex})}>
                                                                        <RemoveIcon color="var(--primary-color)"/>
                                                                    </span>
                                                                </div>
                                                                
                                                            </Row>
                                                        </>               
                                                    })
                                                }
                                            </Col>

                                        </Row>
                                    </div>
                                
                                </>
                            })
                        }
                        <Row>
                            <CustomButton onClick={handleAddExperience} margin="2px 0px 0px 30px" width={"200px"} padding="4px"  label={"Add Experience"}  />
                        </Row>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="resume-section-title">Projects</Form.Label>
                        {
                            inputs.projects.map((proj: Project, index: number) => (
                                <>
                                    <div key={index} className="resume-section">
                                        <span className="resume-remove-section" onClick={(e)=>handleRemoveSection(e,{name:"projects",index:index})}>
                                            <RemoveIcon color="var(--primary-color)"/>
                                        </span>
                                        <Row className="mb-3" key={index}>
                                            <Col>
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="Name" 
                                                    name="name"
                                                    value={proj.name}
                                                    onChange={(e)=>handleChange(e,{name:"projects",index:index})}
                                                />
                                            </Col>
                                            
                                            <Col>
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="Reference" 
                                                    name="ref"
                                                    value={proj.ref}
                                                    onChange={(e)=>handleChange(e,{name:"projects",index:index})}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col>
                                                {   
                                                    inputs.projects[index].description.map((desc: string, descIndex: number) => {
                                                        return <>
                                                            <Row className="m-1">
                                                            <div key={index} className="resume-sub-section">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter Description"
                                                                    value={desc}
                                                                    name="description"
                                                                    onChange={(e)=>handleChange(e,{name:"projects",index:index,microIndex:descIndex})}
                                                                />
                                                                 <span className="resume-remove-sub-section" onClick={(e)=>handleRemoveSubSection(e,{name:"projects",index:index,microIndex:descIndex})}>
                                                                    <RemoveIcon color="var(--primary-color)"/>
                                                                </span>
                                                            </div>
                                                            </Row>
                                                        </>               
                                                    })
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                
                                </>
                            ))
                        }
                        <Row>
                            <CustomButton onClick={handleAddProject} margin="2px 0px 0px 30px" width={"200px"} padding="4px"  label={"Add Project"}  />
                        </Row>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="resume-section-title">Skills</Form.Label>
                        {
                            inputs.skills.map((skill: Skill, index: number) => (
                                <>
                                    <div key={index} className="resume-section">
                                        <span className="resume-remove-section" onClick={(e)=>handleRemoveSection(e,{name:"skills",index:index})}>
                                            <RemoveIcon color="var(--primary-color)"/>
                                        </span>
                                        <Row className="mb-3" key={index}>
                                            <Col md={3}> {/* 4 out of 12 columns = 1/3 */}
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="Category" 
                                                    name="category"
                                                    value={skill.category}
                                                    onChange={(e)=>handleChange(e,{name:"skills",index:index})}
                                                />
                                            </Col>
                                            <Col md={6}> {/* 6 out of 12 columns = 2/3 */}
                                                <div>
                                                    {
                                                        skill.technologies.map((tech: string, techIndex: number) => (
                                                            <span key={techIndex} onClick={(e)=>removeTechnology(e,{category:skill.category,index:techIndex})}>
                                                                <CustomBadge  key={techIndex} label={tech} />
                                                            </span>
                                                        ))
                                                    }
                                                </div>
                                            </Col>
                                            <Col md={3}> {/* 4 out of 12 columns = 1/3 */}
                                                <InputGroup>
                                                    <Form.Control 
                                                        type="text"
                                                        placeholder="Skill"
                                                        name="technologies"
                                                    />
                                                    <InputGroup.Text onClick={(e)=>handleAddTechnology(e,{skill:skill.category})} id="basic-addon1">+</InputGroup.Text>
                                                </InputGroup>
                                                
                                            </Col>
                                        </Row>
                                    </div>
                                </>
                            ))
                        }
                        <Row>
                            <CustomButton onClick={handleAddSkill} margin="2px 0px 0px 30px" width={"200px"} padding="4px"  label={"Add Skill"}  />
                        </Row>
                    </Form.Group>
                </Form>
            </div>
            <div className="resume-preview-container"></div>
        </div>
    );
}

export default Resume;
