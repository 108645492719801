import React from "react";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import ProtectedRoute from "../../utils/ProtectedRoute/ProtectedRoute";
import { useAppSelector } from "../../store/hooks";

import Home from "../Home/Home";
import Upload from "../Upload/Upload";
import Jobs from "../Jobs/Jobs";
import Login from "../Login/Login";
import Generate from "../Generate/Generate";
import LandingPage from "../LandingPage/LandingPage";
import SignUp from "../SignUp/SignUp";
import Logout from "../Logout/Logout";
import Contact from "../Contact/Contact";
import Creds from "../Creds/Creds";
import Feedback from "../Feedback/Feedback";
import Stats from "../Stats/Stats";
import Account from "../Account/Account";
import YourResumes from "../YourResumes/YourResumes";
import CreateResume from "../CreateResume/CreateResume";

const App:React.FC = () => {
    const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);

    // console.log("App isAuthenticated: ", isAuthenticated);
    return (
        <Router>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/home" element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Home />
                    </ProtectedRoute>
                } />

                <Route path="/account" element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Account />
                    </ProtectedRoute>
                } />
                <Route path="/creds" element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Creds />
                    </ProtectedRoute>
                } />
                <Route path="/upload" element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Upload />
                    </ProtectedRoute>
                } />
                <Route path="/jobs" element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Jobs />
                    </ProtectedRoute>
                } />
                <Route path="/login" element={
                    <Login />
                } />
                <Route path="/generate" element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Generate />
                    </ProtectedRoute>
                } />
                <Route path="/createResume" element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <CreateResume />
                    </ProtectedRoute>
                } />
                <Route path="/contact" element={ 
                    <Contact />
                } />
                <Route path="/feedback" element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Feedback />
                    </ProtectedRoute>
                } />
                <Route path="/stats" element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <Stats />
                    </ProtectedRoute>
                } />
                <Route path="/resumes" element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                        <YourResumes />
                    </ProtectedRoute>
                } />
                
                <Route path="/logout" element={<Logout />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="*" element={<h1>404 Not Found</h1>} />
            </Routes>
        </Router>
    );
}

export default App;


