import React from "react";
import Modal from 'react-bootstrap/Modal';
import CustomButton from "../Button/CustomButton";

interface CustomModalProps {
    show: boolean;
    handleClose?: () => void;
    handleSave?: () => void;
    closeLabel?: string;
    saveLabel?: string;
    title: string;
    body: React.ReactNode;
}

const CustomModal: React.FC<CustomModalProps> = ({show,title,body,handleClose,handleSave,closeLabel,saveLabel}) => {

    const cLabel=closeLabel || "Close";
    const sLabel=saveLabel || "Save";

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    body
                }
            </Modal.Body>
            <Modal.Footer>
                <CustomButton onClick={handleClose} label={cLabel}/>
                <CustomButton onClick={handleSave} label={sLabel}/>
            </Modal.Footer>
      </Modal>
    );
}

export default CustomModal;


