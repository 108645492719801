// components/ProtectedRoute.tsx
import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import useAuthHandler from "../hooks/useAuthHandler";



interface ProtectedRouteProps {
    isAuthenticated: boolean;
    children: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ isAuthenticated, children }) => {
    const navigate = useNavigate();
    const accessToken=localStorage.getItem("accessToken") || "";
    const refreshToken=localStorage.getItem("refreshToken") || "";
    const {handleAuth} = useAuthHandler();

    // console.log("ProtectedRoute accessToken: ", accessToken);
    useEffect(() => {
        if(!isAuthenticated){
            // console.log("user not authenticated");
            // console.log("ProtectedRoute accessToken: ", accessToken);
            const getUser=async ()=>{
                // console.log("Access Token : ",accessToken);
                if (accessToken && refreshToken) {
                    handleAuth({status:200,data:{access_token:accessToken,refresh_token:refreshToken}});
                }else{
                    navigate("/login");
                }
            }   
            if(accessToken==="" || refreshToken===""){
                navigate("/login");
                return;
            }
            getUser();
        }

        // console.log("posting a message to parent window");

        window.parent.postMessage({
            type:"TOKEN",
            token:{
                access_token:accessToken,
                refresh_token:refreshToken
            }
        },'*');

    },[isAuthenticated,navigate,handleAuth,accessToken,refreshToken]);

    // console.log("ProtectedRoute isAuthenticated: ", isAuthenticated);
    return isAuthenticated ? children :<></>;
};

export default ProtectedRoute;
