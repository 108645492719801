import React,{useState} from "react";
import "./SignUp.css";

import { FloatingLabel } from "react-bootstrap";
import { Form } from "react-bootstrap";


import api from "../../managers/api/api";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../utils/Custom/Button/CustomButton";
import Dialog from "../../utils/Custom/Dialog/Dialog";
import useAuthHandler from "../../utils/hooks/useAuthHandler";
import GoogleAuthComp from "../GoogleAuthComp/GoogleAuthComp";
// console.log("google client id : ",CLIENT_ID);
const SignUp: React.FC = () => {

    const navigate = useNavigate();
    const {handleAuth }= useAuthHandler();
    const [inputs, setInputs] = useState({name:"", email: '', password: '' });
    const [feedback, setFeedback] = useState<string>("");
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [variantType, setVariantType] = useState<string>("danger");


    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setFeedback("");
        setShowDialog(false);
        setVariantType("danger");
        api.removeAuthHeader()
        const response = await api.signup(inputs.name,inputs.email, inputs.password);
        setFeedback(response.message);
        setShowDialog(true);
        setVariantType(response.variantType || "danger");

        handleAuth(response);
    }

    return (
        <>
            <div className="signup-container">
                <div className="signup-container-header">
                    <h1>Signup</h1>
                </div>
                <div className="signup-container-body">
                    <div className="signup-warn-text" >
                        <span>Note : You cannot change your email after signup!</span>
                    </div>
                    <Form noValidate validated={true} onSubmit={handleSubmit}>
                        <FloatingLabel controlId="signup-name" label="Name">
                            <Form.Control
                                type="text"
                                placeholder="Name"
                                className="mb-3"
                                name="name"
                                value={inputs.name}
                                onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
                                required
                            />
                        </FloatingLabel>
                        <FloatingLabel controlId="signup-email" label="Email address">
                            <Form.Control
                                type="email"
                                placeholder="name@example.com"
                                className="mb-3"
                                name="email"
                                value={inputs.email}
                                onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
                                required
                            />
                        </FloatingLabel>
                        <FloatingLabel controlId="signup-password" label="Password">
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                className="mb-3"
                                name="password"
                                value={inputs.password}
                                onChange={(e) => setInputs({ ...inputs, password: e.target.value })}
                                required
                            />
                        </FloatingLabel>
                        <Dialog 
                            show={showDialog} 
                            message={feedback} 
                            variantType={variantType}
                        />
                        <CustomButton
                            type="submit"
                            label="Signup"
                            margin="30px 0px 0px 0px"
                            padding="10px 20px"
                        />
                    </Form>
                </div>
                <div className="signup-container-footer">
                    <p onClick={()=>navigate("/login")}>Back to Login</p>
                </div>
                <div>
                   <GoogleAuthComp requestType="signup" />
                </div>
            </div>
        </>
    );
}

export default SignUp;


