import React from 'react';
import Alert from 'react-bootstrap/esm/Alert';

interface DialogProps{
    show:boolean;
    message:string;
    variantType:string;
}

const Dialog:React.FC<DialogProps>=({show,message,variantType})=>{

    // const [show,setShow]=useState(false);
    if(show){
        return <>
            <Alert style={{marginTop:"10px"}} variant={variantType} >
                {message}
            </Alert>
        </>
    }else{
        return <></>
    }
    

}


export default Dialog;