import React, { useRef,useState } from 'react'
import './CreateResume.css'
import Header from '../Header/Header'

import { UserResume,InitialResume} from '../../utils/_interfaces/Resume'

import { useAppSelector } from '../../store/hooks'
import { InputGroup } from 'react-bootstrap'
import { Form } from 'react-bootstrap'
import Resume from '../Resume/Resume'
import CustomButton from '../../utils/Custom/Button/CustomButton'

import { useAppDispatch } from '../../store/hooks'
import api from '../../managers/api/api'
import CustomModal from '../../utils/Custom/Modal/CustomModal'
import { saveNewUserResume } from '../../store/UserSlice'

const CreateResume:React.FC = () => {
    const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated)
    const dispatch = useAppDispatch()
    const resumeRef=useRef<{getResume:()=>UserResume}>(null);

    const [inputs, setInputs] = useState({
        "show":false,
        "fileName":"",
        "nickName":""
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setInputs((prev) => ({ ...prev, [name]: value }))
    }
    
    const handleCreateResume = async () => {
    
        if(resumeRef.current){
            let resume:UserResume=resumeRef.current.getResume();
            // console.log("Resume : ",resume);

            if(resume.fullName==="" || resume.email==="" || resume.phone===""){
                console.log("Please fill all the fields");
                return
            }

            setInputs((prev) => ({ ...prev, "show":true }))

            // const response=await api.createNewResume(resume)

            // console.log("Response : ",response);
        }
    }

    const handleSaveResume = async () => {
        if(resumeRef.current){
            let resume:UserResume=resumeRef.current.getResume();
            const response=await api.createNewResume(resume,inputs.fileName,inputs.nickName)
            console.log("Response : ",response);

            if(response.status===200){
                setInputs((prev) => ({ ...prev, "show":false }))

                const filename=response.data.filename;
                const nickname=response.data.nickname;
                const newResume=response.data.resume;
                const id=response.data.file_id;

                dispatch(saveNewUserResume({filename,nickname,resume:newResume,key:id}))

            }
        }
    }

    const handleClose = () => {
        setInputs((prev) => ({ ...prev, "show":false }))
    }

    const modalElements=()=>{
        return <>
            <InputGroup className="mb-3">
                <InputGroup.Text>File Name</InputGroup.Text>
                <Form.Control 
                    type="text" 
                    name="fileName" 
                    value={inputs.fileName} 
                    onChange={handleChange} 
                    placeholder="John_Doe_Resume"
                />
            </InputGroup>
            <InputGroup className="mb-3">
                <InputGroup.Text>Nickname</InputGroup.Text>
                <Form.Control 
                    type="text" 
                    name="nickName" 
                    value={inputs.nickName} 
                    onChange={handleChange} 
                    placeholder="my-resume"
                />
            </InputGroup>
        </>
    }


    return (
        <>
            <Header isAuthenticated={isAuthenticated} />
            <div className='create-resume-container'>
                <div className='create-resume-header'>
                    <h3>Create Resume</h3>
                </div>
                <div className='create-resume-body'>
                    <Resume resume={InitialResume} ref={resumeRef}/>
                </div>
                <div className='create-resume-footer'>
                    <CustomButton margin="10px 0px 40px 0px" label='Create Resume' onClick={()=>handleCreateResume()}/>
                </div>
            </div>
            <CustomModal 
                show={inputs.show}
                title="Set Names"
                body={modalElements()}
                handleClose={handleClose}
                handleSave={handleSaveResume}
                closeLabel='Cancel'
                saveLabel='Create'
            />
        
        </>
        
    )
}

export default CreateResume


