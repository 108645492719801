import React,{useState} from "react";
import './Jobs.css';

import { Form } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import CustomSpinner from "../../utils/Custom/Spinner/CustomSpinner";
import { useAppSelector } from "../../store/hooks";
import Header from "../Header/Header";

import api from "../../managers/api/api";
import SendIcon from "../../icons/SendIcon";
import CustomButton from "../../utils/Custom/Button/CustomButton";

const Jobs: React.FC = () => {

    const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);

    const [isInitiated, setIsInitiated] = useState<boolean>(true);
    const [feedback, setFeedback] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [jobs, setJobs] = useState<any[]>([]);

    const [query, setQuery] = useState<string>('');

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        // console.log(e.target.value);
        setQuery(e.target.value);
    }

    const handleSubmitQuery = async () => {
        setIsInitiated(false);
        setFeedback('');
        setIsLoading(true);
        setJobs([]);
        console.log("Query : ",query);

        if(query === ''){
            console.log("Query is empty");
            setFeedback("Type in Something to search for jobs");
            return;
        }

        const response = await api.search_jobs(query);
        
        if(response.status === 200){
            console.log("Jobs : ",response.data);
            if(response.data.items.length === 0){
                setFeedback("No jobs found. Try something else...");
            }
            else{
                setJobs(response.data.items);
            }
        }

        if(response.status === 400){
            setFeedback(response.data.message);
        }

        setIsLoading(false);

        // console.log("Response : ",response);
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if(e.key === 'Enter'){
            e.preventDefault();
            handleSubmitQuery();
        }
    }

    return <>
        <Header isAuthenticated={isAuthenticated} />
        <div className="jobs-container">
            <div className="job-search-container">
                <div className="job-list-container">
                    {
                        isInitiated && 
                            <div className="job-actions-container">
                                <div className="job-action">
                                    <p>Search for a job</p>
                                </div>
                            </div>
                    }
                    {
                        feedback !== '' &&
                            <div className="job-feedback-container">
                                <p>{feedback}</p>
                            </div>
                    }
                    {
                        isLoading?<>
                            <CustomSpinner />
                        </> :jobs.length > 0 &&
                            <div className="job-list">
                                {
                                    jobs.map((job) => {
                                        return <>
                                            <div className="job">
                                                <div className="job-header">
                                                    <div className="job-header-l">
                                                        <p>{job.Company}</p>
                                                        <p>{job.City}</p>
                                                    </div>
                                                    <div className="job-header-r">
                                                        <p>{job.JobTitle}</p>
                                                        <CustomButton label="Apply" padding="5px 10px" margin="0px" onClick={()=>{
                                                            let link=""
                                                            if(job.portal==="indeed"){
                                                                link=`https://www.indeed.com/viewjob?jk=${job.job_id}`
                                                            }else{
                                                                link=job.portalLink
                                                            }
                                                            window.open(link,"_blank")
                                                        }}></CustomButton>
                                                    </div>
                                                </div>
                                                <div className="job-body">
                                                    <p>{job.JobDescription}</p>
                                                </div>
                                                
                                            </div>
                                        </>
                                        
                                    })
                                }
                            </div>
                    }
                </div>
                <div className="job-query-container">
                    <div className="query-box">
                        <InputGroup>
                            <Form.Control 
                                as="textarea"
                                rows={2}
                                value={query}
                                onKeyDown={handleKeyDown}
                                onChange={handleChange}
                                placeholder="Type in your query here"
                            />
                            <div className="send-icon-container" onClick={handleSubmitQuery}><SendIcon color="white"></SendIcon> </div>
                        </InputGroup>
                    </div>
                    
               </div>
            </div>
            {/* <div className="job-queue-container">

            </div> */}
        </div>
    </>
}


export default Jobs;


