import React from "react";
import "./Account.css";

import Header from "../Header/Header";



import { useAppSelector } from "../../store/hooks";

const Account: React.FC = () => {

    const user = useAppSelector((state) => state.user);
    const isAuthenticated = user.isAuthenticated;


    return (
        <>
            <Header isAuthenticated={isAuthenticated} />
            <div className="account-container">
                <span>Name : {user.name}</span>
                <span>{user.email}</span>
                
            </div>
        </>
        
    )
}

export default Account

