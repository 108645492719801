import React,{useState,useRef}from "react";
import "./PdfViewer.css";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import Resume from "../Resume/Resume";

import { UserResume } from "../../utils/_interfaces/Resume";
import { useAppSelector } from "../../store/hooks";
import { useAppDispatch } from "../../store/hooks";
import api from "../../managers/api/api";

import RefreshIcon from "../../icons/RefreshIcon";
import EditIcon from "../../icons/EditIcon";
import { setResumeURL } from "../../store/AppSlice";
import { setGeneratedResume,saveNewUserResume } from "../../store/UserSlice";
import DownloadIcon from "../../icons/DownloadIcon";
import CustomButton from "../../utils/Custom/Button/CustomButton";
import CustomSpinner from "../../utils/Custom/Spinner/CustomSpinner";

interface PdfViewerProps {
    pdfUrl: string;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ pdfUrl }) => {

    const [edit, setEdit] = useState(false);
    const generatedResumeRef=useRef<{getResume:()=>UserResume}>(null);
    const dispatch=useAppDispatch();
    const fileName = useAppSelector((state) => state.user.name).replaceAll(" ","_");
    const generatedResume=useAppSelector((state)=>state.user.generatedResume);

    const [savingToYourResume,setSavingToYourResume]=useState<boolean>(false);

    const handleResumeRefresh=async ()=>{
        if(generatedResumeRef.current){
            let resume:UserResume=generatedResumeRef.current.getResume();
            console.log("new Resume : ",resume);
            
            const response=await api.getNewResume(resume);

            if(response.status!==200) return;

            const newResume=response.data.resume
            const refreshedResume=response.data.refreshedResume;
            const blob = new Blob([Uint8Array.from(atob(newResume), (c) => c.charCodeAt(0))], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);

            dispatch(setResumeURL(url));
            dispatch(setGeneratedResume(refreshedResume));
            setEdit(false);
           
        }
    }

    const handleSaveToYourResumes=async ()=>{
        console.log("Saving to your resumes");
        if(generatedResume){
            setSavingToYourResume(true);

            const response=await api.saveToYourResumes(generatedResume);
            if(response.status===200){
                console.log("Resume Saved Successfully");
                const resume=response.data.resume
                const fileId=response.data.file_id;
                const nickname=response.data.nickname;
                const filename=response.data.filename;
                dispatch(saveNewUserResume({
                    key:fileId,
                    resume:resume,
                    nickname:nickname,
                    filename:filename
                }));
            }else{
                console.log("Error in Saving Resume");
            }

            setSavingToYourResume(false);
        }
    }

    const handleDownload=()=>{
        const link = document.createElement('a');
        link.href = pdfUrl; // File URL
        link.download = `${fileName}.pdf`; // File name to save
        link.click(); // Trigger the click event
    }

    return (
        <>
            <div className="pdf-menu-bar">
                <div className="pdf-menu-items">
                    <button className="pdf-menu-btn" onClick={()=>setEdit((prev)=>!prev)}><EditIcon color="white" /></button>
                    <button className="pdf-menu-btn" onClick={handleResumeRefresh}><RefreshIcon color="white"/></button>
                    <button className="pdf-menu-btn" onClick={handleDownload}><DownloadIcon color="white"/></button>
                </div>
                <div className="pdf-menu-items">
                    {
                        savingToYourResume?<CustomSpinner />: 
                    <CustomButton label="Save to your resumes" onClick={()=>handleSaveToYourResumes()} />
                    }
                </div>
            </div>
        
            <div style={{ height: "80%",
                width:"100%",
                overflow:"auto",
                backgroundColor:"var(--primary-diluated-color)",
            }}>
                {
                    edit ?
                    <Resume resume={generatedResume} ref={generatedResumeRef}  />
                    :
                    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
                        <Viewer 
                            fileUrl={pdfUrl}
                        />
                    </Worker>
                    

                }
                
            </div>
        </>
    );
};

export default PdfViewer;
